<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <div>
          <vx-card title="">
            <div class="my-1">
              <vs-table :data="data" :noDataText="placeholderText">
                <template slot="thead">
                  <vs-th>id</vs-th>
                  <vs-th>รหัส</vs-th>
                  <vs-th>ชื่อบัญชี</vs-th>
                  <vs-th>เลขบัญชี</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="index" v-for="(tr, index) in data">
                    <vs-td :data="tr.bank_id">
                      {{ tr.bank_id }}
                    </vs-td>
                    <vs-td :data="tr.bank_code">
                      {{ tr.bank_code }}
                    </vs-td>
                    <vs-td :data="tr.bank_fullname">
                      {{ tr.bank_fullname }}
                    </vs-td>
                    <vs-td :data="tr.bank_number">
                      {{ tr.bank_number }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  components: {},
  data () {
    return {
      data: {},
      placeholderText: 'โปรดรอสักครู่...',
      fromCategory: null,
      toCategory: null,
      fromAccount: null,
      toAccount: null,
      amount: null,
      otp: null,
      isFuckingLoading: false
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    },
    fromAccountList () {
      return this.data[this.fromCategory]
    },
    toAccountList () {
      return this.data[this.toCategory]
    }
  },
  async mounted () {
    const fetch = await axios.get('/internal-transfer/list-all')
    if (fetch && fetch.data && fetch.data.success) {
      this.data = fetch.data.data
    } else {
      this.$vs.notify({
        time: 4000,
        color: 'danger',
        position: 'top-right',
        icon: 'error',
        title: 'เกิดข้อผิดพลาดในการดึงข้อมูล',
        text: this.status_wd.info
      })
    }
  },
  methods: {
    async transfer () {
      this.isFuckingLoading = true

      const reqTransfer = await axios.post('/internal-transfer/transfer', {
        fromCategory: this.fromCategory,
        fromAccount: this.fromAccount,
        toCategory: this.toCategory,
        toAccount: this.toAccount,
        amount: this.amount,
        otp: this.otp
      })

      if (reqTransfer && reqTransfer.data && reqTransfer.data.success) {
        this.$vs.notify({
          time: 4000,
          color: 'success',
          position: 'top-right',
          icon: 'success',
          title: 'โยกเงินสำเร็จ',
          text: reqTransfer.data.message
        })
        this.clearData()
      } else {
        this.$vs.notify({
          time: 4000,
          color: 'danger',
          position: 'top-right',
          icon: 'danger',
          title: 'โยกเงินไม่สำเร็จ',
          text: reqTransfer.data.message
        })
      }

      this.isFuckingLoading = false
    },
    clearData () {
      this.amount = null
      this.otp = null
      this.isFuckingLoading = false
    }
  }
}
</script>

<style lang="scss"></style>
